* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 5px;
  height: 100%;
  text-align: left;
  font-family: Arial,sans-serif;
  color: #333;
}
div#container {
  flex-wrap: wrap;
  margin-top: 5px;
}

aside {
  float: left;
  width: 200px;
  height: 100%;
  padding: 10px;
}

aside select {
  margin-bottom: 20px;
}

main {
  display: block;
  width: 100%;
  margin-left: 200px;
  padding: 10px;
}

/* viewport <= 1000px */
@media screen and (max-width: 1000px) {
  * {
    font-size: 1em;
  }
}

/* viewport <= 630px */
@media screen and (max-width: 630px) {
}

/* viewport <= 500px */
@media screen and (max-width: 500px) {
  * {
    font-size: 0.9em;
  }
}

#tradeInfo {
  font-size: 14px;
  width: 100%;
  height: 100%;
  border: 1px solid #000000;
}

th button.desc {
  background-color: red;
}
th button.asc {
  background-color: green;
}

tr.irrelevant {
  background-color: grey;
}
tr.shitcoin {
  background-color: green;
}
tr.project {
  background-color: lightgreen;
}
tr.researcher {
  background-color: yellow;
}
tr.investor {
  background-color: orange;
}

td.flag select {
  width: 80px;
}

div#pagination {
  text-align: center;
}
div#pagination button.more {
  padding: 5px 20px
}

div#filters {
  margin-bottom: 20px;
}
div#filters li {
  margin-bottom: 5px;
}
div#filters select {
  width: 100px;
}
