* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: left;
  font-family: Arial,sans-serif;
  color: #333;
}
div#container {
  flex-wrap: wrap;
  margin-top: 5px;
}

aside {
  float: left;
  width: 200px;
  height: 100%;
  padding: 10px;
}

aside select {
  margin-bottom: 20px;
}

main {
  display: block;
  width: 100%;
  margin-left: 200px;
  padding: 10px;
}

select {
  width: 100%;
}

/* viewport <= 1000px */
@media screen and (max-width: 1000px) {
  * {
    font-size: 1em;
  }
}

/* viewport <= 630px */
@media screen and (max-width: 630px) {
}

/* viewport <= 500px */
@media screen and (max-width: 500px) {
  * {
    font-size: 0.9em;
  }
}

#tradeInfo {
  font-size: 14px;
  width: 100%;
  height: 100%;
  border: 1px solid #000000;
}

.positive {
  color: #26a69a;
}
.negative {
  color: #ff5252;
}
