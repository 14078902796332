main {
  position: relative;
}

#switcher {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
  color: #2196F3;
}

.switcher-item {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  padding: 6px 8px;
  font-size: 14px;
  color: #262b3e;
  background-color: transparent;
  margin-right: 8px;
  border: none;
  border-radius: 4px;
  outline: none;
}

.switcher-item:hover {
  background-color: #f2f3f5;
}

.switcher-item.active {
  text-decoration: none;
  cursor: default;
  color: #262b3e;
}

.switcher-item.active, .switcher-item.active:hover {
  background-color: #e1eff9;
}

#indicators {
  position: absolute;
  top: 15px;
  left: 350px;
  width: 500px;
}
#indicatorsInput {
  width: 300px;
}

.floating-tooltip-2 {
  width: 220px;
  height: 50px;
  position: absolute;
  display: none;
  padding-left: 5px !important;
  padding-top: 3px !important;
  box-sizing: border-box;
  font-size: 12px;
  color: #131722;
  background-color: rgba(255, 255, 255, 1);
  text-align: left;
  z-index: 1000;
  top: 12px;
  left: 0px;
  pointer-events: none;
  border: 1px solid rgba(255, 70, 70, 1);
  border-radius: 2px;
}
